/* Customization Guide available on:
  https://gatsby-blog-mdx.now.sh/2020/05/4-customize/
  ======================================== */
const config = {
  /* Site
  ========================================= */
  title: `Dilshan Kelsen`,                                        // Displayed in header
  description: `Tutorials about full-stack web development`,      // Site description
  maxWidth: 800,                                                  // Max pixel width of website
  enableAbout: false,                                             // Enables about page
  siteUrl: `https://dilshankelsen.com/`,                          // For sitemap & RSS feed
  useScrollIndicator: true,                                       // Use scroll indicator on top of screen in posts

  /* Profile
  ========================================= */
  author: `Dilshan Kelsen`,                    // Name shows on profile
  profileDescription: "Code and stuff",        // Shows under author name
  profileImageName: `profile.jpg`,             // Place profile.jpg/.jpeg/.png in _assets folder
  location: "",                                // Location under profileDescription. "" --> disabled
  footerLink: "",                              // Link to page when you click footer name

  /* Social Media Links
      accountName & emailAddress: leave blank ("") to disable
      showHeaderIcon: shows social media icon in header. When true, must have account name set
  ========================================= */
  socialMediaLinks: {
    email:     { emailAddress: "dilshan.kelsen@gmail.com", showHeaderIcon: true },
    github:    { accountName: "dkelsen", showHeaderIcon: true },
    facebook:  { accountName: "", showHeaderIcon: true },
    instagram: { accountName: "", showHeaderIcon: true },
    twitter:   { accountName: "", showHeaderIcon: true },
    linkedIn:  { accountName: "in/dilshan-kelsen", showHeaderIcon: true },    // Use URL after "linkedin.com/"
    medium:    { accountName: "", showHeaderIcon: true },                     // Use URL after "medium.com/@"
  },

  /* Social Media Share Buttons--available below every post
  ========================================= */
  shareButtons: { email: true, facebook: true, twitter: true, reddit: false, linkedIn: false },

  /* Comments
  ========================================= */
  comments: {
    facebook: { enabled: false, appId: "" },                                    // appId: optional, but used to moderate fb comments
    disqus: { enabled: false, shortName: "" },                                  // Check your shortName on https://disqus.com
    utterances: { enabled: true, repoUrl: "dkelsen/gatsby-blog-comments" },     // i.e. 'ellismin/gatsby-blog-mdx'
  },

  /* More Custom Settings
  ========================================= */
  defaultTheme: "light",                          // Options: dark / light -- applied to first visitors
  showTimeToRead: false,                          // Shows time to read for each post in main page
  breakCodeLines: false,                          // Breaks long code lines instead of having horizontal scroll
  faviconSrc: `_assets/icon-code-rainbow.png`,    // Favicon
  gtagId: `G-7TTKPRPKRF`,                         // Your google analytics tracking id--i.e. UA-*****
  googleAdSenseId: ``,                            // Your google AdSense client id--i.e. ca-pub-****
}

module.exports = config
